import PropTypes from '@root/vendor/prop-types';
import React, { lazy } from '@root/vendor/react';
import { FunnelStages } from '@root/core/src/models/funnel-stages';
import { Route, Switch } from '@root/core/src/components/route';

const TriageEntry = lazy(() => import(/* webpackChunkName: "triage" */ '@root/triage/src/triage-entry'));
const RestrictedFlowEntry = lazy(() => import(/* webpackChunkName: "restricted-flow" */ '@root/restricted-flow/src/restricted-flow-entry'));
const PasswordResetEntry = lazy(() => import(/* webpackChunkName: "landing" */ '@root/password-reset/src/password-reset-entry'));
const WelcomeBackEntry = lazy(() => import(/* webpackChunkName: "welcome-back" */ '@root/welcome-back/src/welcome-back-entry'));
const Logout = lazy(() => import(/* webpackChunkName: "logout" */ '@root/quote.joinroot.com/src/components/logout'));
const CustomerSurveysEntry = lazy(() => import(/* webpackChunkName: "customer-surveys" */ '@root/customer-surveys/src/customer-surveys-entry'));

export default function ProtectedRouter({ passProps }) {
  return (
    <Switch>
      <Route
        funnelStage={FunnelStages.ACCOUNT}
        path={'/password-reset'}
        render={() => <PasswordResetEntry passProps={passProps} />}
      />
      <Route
        funnelStage={FunnelStages.ACCOUNT}
        path={'/customer-surveys'}
        render={() => <CustomerSurveysEntry passProps={passProps} />}
      />
      <Route
        funnelStage={FunnelStages.ACCOUNT}
        path={'/triage'}
        render={() => <TriageEntry />}
      />
      <Route
        funnelStage={FunnelStages.ACCOUNT}
        path={'/welcome-back'}
        render={() => <WelcomeBackEntry passProps={passProps} />}
      />
      <Route
        funnelStage={FunnelStages.ACCOUNT}
        path={'/logout'}
        render={() => <Logout passProps={passProps} />}
      />
      <Route
        path={'(.*)'}
        render={() => <RestrictedFlowEntry passProps={passProps} />}
      />
    </Switch>
  );
}

ProtectedRouter.propTypes = {
  passProps: PropTypes.object.isRequired,
};
